.book-item {
  width: 100%;
}

.book-item img {
  width: 100%;
  object-fit: contain;
}

.book-item .icon-container {
  background-color: #e5ecf2;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0;
  cursor: pointer;
}

.book-item .icon-container-success {
  background-color: #67beec;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0;
  cursor: pointer;
}

.book-item .disabled {
  cursor: default;
}
