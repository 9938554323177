.pg-dialog-title {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

.pg-password-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pg-password-letter {
  display: flex;
  width: 60px;
  height: 80px;
  background-color: rgba(103, 188, 236, 0.25);
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.pg-password-letter:not(:last-of-type) {
  margin-right: 10px;
}

.pg-password-letter span {
  display: inline-block;
  font-size: 40px;
  font-weight: 500;
  color: #344767;
}

.pg-icon-container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.pg-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
