.new-message-container {
  position: relative;
}

.new-message-container .new-message-audio-record-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.new-message-audio-record-btn .pause {
  background-color: #dd6363;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.select-all-container {
  display: flex;
  align-items: center;
}
