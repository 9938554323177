.event-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  cursor: pointer;
  flex-wrap: wrap;
}

.event-title {
  white-space: normal;
}

.event-tasks {
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.event-task-item {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 2px;
}

.event-task-item:last-of-type {
  margin-right: 0px;
}
