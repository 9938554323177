.book-detail-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.book-detail-container .book-cover {
  margin-right: 24px;
}

.book-detail-container .book-cover img {
  width: 150px;
  height: 100%;
  vertical-align: top;
}

.book-detail-container .book-info {
  flex: 1;
}

.book-detail-container .book-info .book-info-buttons-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 24px;
}

.searchbox-wrapper {
  position: relative;
  padding-block-start: 1.17em;
  padding-block-end: 1.17em;
}

.searchbox-wrapper .searchbox-container {
  font-family: inherit;
  margin-right: 10px;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
}

.searchbox-wrapper .searchbox-container input {
  font-family: inherit !important;
  letter-spacing: 0.13132px !important;
  border-radius: 8px !important;
  color: #344767 !important;
}

.searchbox-wrapper .searchbox-container input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}
.searchbox-wrapper .searchbox-container input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}
.searchbox-wrapper .searchbox-container input::placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}

.searchbox-wrapper .searchbox-container .react-search-box-dropdown {
  margin-top: 2px;
  border-radius: 8px;
}

.searchbox-wrapper .searchbox-container .react-search-box-dropdown ul {
  font-size: 14px;
}

.searchbox-wrapper .searchbox-container .react-search-box-dropdown ul > li:hover {
  background-color: rgba(0, 0, 0, 0.25);
  color: white;
  font-size: 15px;
}

.searchbox-wrapper .searchbox-container .react-search-box-dropdown ul > li:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.searchbox-wrapper .searchbox-container .react-search-box-dropdown ul > li:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.task-types-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* justify-content: flex-start; */
  flex-wrap: wrap;
}

.checkbox-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
}

.checkbox-group {
  margin-right: 10px;
}
/* .checkbox-group:not(:last-child) {
  margin-right: 10px;
} */

.checkbox-group .checkbox-label-icon {
  margin-left: 5px;
  padding: 0;
}

.checkbox-group .not-selected {
  background-color: rgba(0, 0, 0, 0.25);
}

.checkbox-group .blue {
  background-color: #67bcec;
}

.checkbox-group .purple {
  background-color: #be5fb5;
}

.checkbox-group .not-selected:hover,
.checkbox-group .not-selected:active,
.checkbox-group .not-selected:visited,
.checkbox-group .not-selected:focus,
.checkbox-group .not-selected:focus:not(:hover),
.checkbox-group .not-selected:disabled,
.checkbox-group .not-selected[disabled] {
  background-color: rgba(0, 0, 0, 0.25);
}

.checkbox-group .blue:hover,
.checkbox-group .blue:active,
.checkbox-group .blue:visited,
.checkbox-group .blue:focus,
.checkbox-group .blue:focus:not(:hover),
.checkbox-group .blue:disabled,
.checkbox-group .blue[disabled] {
  background-color: #67bcec;
}

.checkbox-group .purple:hover,
.checkbox-group .purple:active,
.checkbox-group .purple:focus,
.checkbox-group .purple:focus:not(:hover),
.checkbox-group .purple:disabled,
.checkbox-group .purple[disabled] {
  background-color: #be5fb5;
}

.message-select-container {
  margin-right: 10px;
}

.message-container {
  position: relative;
}

.message-container .message-record-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.icon-buttons {
  margin-left: 15px !important;
  padding: 0 !important;
}

.gray {
  background-color: #f1f3f4 !important;
}

.gray:hover,
.gray:active,
.gray:visited,
.gray:focus,
.gray:focus:not(:hover) {
  background-color: #f1f3f4 !important;
}

.error-desc {
  font-size: 16px;
  margin-bottom: 16px;
}

.message-error-list {
  text-align: left;
  line-height: 1.5;
  color: #f27474;
}

.message-error-item {
  font-size: 14px;
}

/* .required-star {
  color: #344767;
} */

.assignment-sub-header {
  display: block;
  font-size: 0.9em;
  margin-block-start: 1.3em;
  margin-block-end: 1.3em;
}
