.navbar-search-wrapper {
  position: relative;
  margin-right: 8px;
  width: 100%;
  flex: 0;
}

.navbar-search-wrapper .navbar-search-container {
  position: absolute;
  width: 250px;
  top: 0;
  right: 0;
  z-index: 1001;
}

.navbar-search-wrapper .navbar-search-container span > input {
  color: rgba(73, 80, 87);
  font-size: 12px;
  letter-spacing: 0.5px;
}

.navbar-search-wrapper .navbar-search-container span > input::placeholder {
  color: rgba(73, 80, 87, 0.4);
}

.navbar-search-wrapper .navbar-search-container span > span {
  top: 3px;
}

.navbar-search-wrapper .navbar-search-container .react-search-box-dropdown-list-item {
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .navbar-search-wrapper {
    flex: 1;
    margin-right: 0;
    margin-top: 8px;
  }
  .navbar-search-wrapper .navbar-search-container {
    position: unset;
    width: 100%;
  }
}
