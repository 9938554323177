.academicWeekPanel {
  max-width: 100%;
  width: 100%;
  padding: 0.5rem;
}

.academicWeekPanel table {
  font-size: 0.875rem;
  border-collapse: collapse;
  width: 100%;
}

.academicWeekPanel th,
.academicWeekPanel td {
  border: 1px solid #e5eaf2;
  background-color: #ffffff;
  text-align: left;
  vertical-align: top;
  padding: 8px;
}

.academicWeekPanel th {
  background-color: #ffffff;
}

.academicCalendarOutput:not(:last-of-type) {
  margin-bottom: 1rem;
}

.academicCalendarLearningActivity:not(:last-of-type) {
  margin-bottom: 1rem;
}

.academicRow {
  width: 100%;
  display: flex;
}

.academicHeader {
  width: 200px;
  font-weight: 500;
}

.academicCell {
  flex: 1;
  min-width: 300px;
}

.academicCalendarOutput ol {
  padding-left: 1.5rem;
}

.academicLearningActivityTitle {
  font-weight: 500;
}

.academicCalendarTools {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
}

.academicCalendarToolsLink {
  color: inherit;
  text-decoration: none;
}

.academicCalendarToolsLink:hover {
  text-decoration: none;
  cursor: pointer;
}

.academicCalendarInterdisciplinary:not(:last-of-type) {
  margin-bottom: 1rem;
}

.academicInterdisciplinaryTitle {
  font-weight: 500;
}
