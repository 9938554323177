.lp-dialog {
  font-size: 16px;
  font-weight: 700;
}

.lp-dialog-header {
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}

.lp-dialog-objective h3 {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
}

.lp-dialog-objective p {
  font-size: 14px;
  font-weight: 400;
}

.lp-dialog-intro h3 {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
}

.lp-dialog-intro p {
  font-size: 14px;
  font-weight: 400;
  white-space: pre-wrap;
}

.lp-dialog-activity h3 {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
}

.lp-dialog-activity p {
  font-size: 14px;
  font-weight: 400;
  white-space: pre-wrap;
}

.lp-dialog-assessment h3 {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
}

.lp-dialog-assessment p {
  font-size: 14px;
  font-weight: 400;
  white-space: pre-wrap;
}

.lp-dialog-material h3 {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
}

.lp-dialog-material a {
  font-size: 14px;
  font-weight: 400;
  white-space: pre-wrap;
  color: #328ef9 !important;
}
