.class-reports-container {
  font-size: 20px;
  line-height: 1;
}

.class-reports-container .class-reports-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.class-reports-container .class-reports-header span {
  font-size: 24px;
  font-weight: 500;
}

.class-reports-container .class-reports-header .logo img {
  height: 40px;
  width: 100%;
  object-fit: cover;
}

.class-reports-container .class-reports-line {
  width: 100%;
  height: 2px;
  background-color: rgb(44, 44, 44);
}

.class-reports-container .section-header {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

/* Class General Info */
.class-reports-container .class-reports-general-info {
  padding: 20px 0;
  margin-bottom: 40px;
}

.class-reports-container .class-reports-items-container {
  padding: 0 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.class-reports-container .class-reports-item {
  margin-top: 20px;
  text-align: center;
  min-width: 175px;
}
.class-reports-container .item-value {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
}
.class-reports-container .item-label {
  font-size: 24px;
  font-weight: 300;
}

/* Charts */
.class-reports-container .chart-container {
  margin-bottom: 100px;
}

.summary-item-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* This part of CSS is for create PDF file */
.class-reports-container-to-pdf {
  margin-top: 300px;
  padding: 40px;
  font-size: 20px;
  line-height: 1;
  display: none;
  width: 1250px;
}

.class-reports-container-to-pdf .class-reports-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.class-reports-container-to-pdf .class-reports-header span {
  font-size: 24px;
  font-weight: 500;
}

.class-reports-container-to-pdf .class-reports-header .logo img {
  height: 40px;
  width: 100%;
  object-fit: cover;
}

.class-reports-container-to-pdf .class-reports-line {
  margin: 20px 0;
  width: 100%;
  height: 2px;
  background-color: rgb(44, 44, 44);
}

.class-reports-container-to-pdf .class-reports-general-info {
  padding: 20px 0;
  margin-bottom: 40px;
}

.class-reports-container-to-pdf .class-reports-general-info .class-reports-items-container {
  padding: 0 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.class-reports-container-to-pdf
  .class-reports-general-info
  .class-reports-items-container
  .class-reports-item {
  margin-top: 20px;
  text-align: center;
  min-width: 175px;
}

.class-reports-container-to-pdf
  .class-reports-general-info
  .class-reports-items-container
  .class-reports-item
  .item-value {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
}

.class-reports-container-to-pdf
  .class-reports-general-info
  .class-reports-items-container
  .class-reports-item
  .item-label {
  font-size: 24px;
  font-weight: 300;
}

.class-reports-container-to-pdf .charts-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.class-reports-container-to-pdf .charts-container .chart-container {
  margin-bottom: 100px;
  width: 580px;
}

.class-reports-container-to-pdf .section-header {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}
