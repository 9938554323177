.custom-alert {
  position: fixed;
  z-index: 2000;
  margin-top: 10px;
  display: block;
  right: 10px;
  width: 50%;
}

.custom-alert strong {
  margin: 0 10px 0 10px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.custom-alert p {
  font-weight: 400;
  font-size: 14px;
}
