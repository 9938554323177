.assignments {
  display: flex;
  width: 100%;
}

.assignments .assignment-btn {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.assignments .prev {
  margin-right: 10px;
}

.assignments .next {
  margin-left: 10px;
}

.assignments .assignments-slider-wrapper {
  padding: 0;
  overflow: hidden;
  position: relative;
  flex: 1;
}

.assignments-slider-wrapper .assignments-slider {
  display: flex;
  position: relative;
  transition: width 2s ease 100ms;
  transition-delay: 1s;
}

.assignments-slider .assignments-slider__contaier {
  display: flex;
  padding: 0;
  transition: transform 300ms ease 100ms;
  z-index: 3;
  width: 100%;
}

.assignments-slider .assignments-slider__contaier .new-item {
  min-width: 160px;
  max-width: 160px;
  height: 100%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #67748e;
  border-radius: 5px;
  cursor: pointer;
}

.assignments-slider .assignments-slider__contaier .assignments-item {
  height: 100%;
  margin: 0 10px;
  cursor: pointer;
}

.assignments-slider .assignments-slider__contaier .assignments-item img {
  height: 100%;
  width: 150px;
  vertical-align: top;
}
