.student-report-container {
  font-size: 20px;
  line-height: 1;
}

.student-report-container .student-report-summary {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.student-report-container .student-container {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}

.student-report-container .student-container img {
  width: 40px;
  transform: translateY(2px);
}

.student-report-container .student-container span {
  display: block;
  height: 100%;
  margin-left: 5px;
}

.student-report-container .summary-item {
  font-weight: 500;
}

.student-report-container .logo img {
  height: 40px;
  width: 100%;
  object-fit: cover;
}

.student-report-container .student-report-line {
  /* margin: 20px 0; */
  width: 100%;
  height: 2px;
  background-color: rgb(44, 44, 44);
}

.student-report-container .student-report-general-info {
  padding: 20px 0;
  margin-bottom: 40px;
}

.student-report-container .student-report-items-container {
  padding: 0 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.student-report-container .student-report-item {
  margin-top: 20px;
  text-align: center;
  min-width: 175px;
}
.student-report-container .item-value {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
}
.student-report-container .item-label {
  font-size: 24px;
  font-weight: 300;
}
.student-report-container .chart-container {
  margin-bottom: 100px;
}

.student-report-container .section-header {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.summary-item-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* This part of CSS is for create PDF file */
.student-report-container-to-pdf {
  margin-top: 300px;
  padding: 40px;
  font-size: 20px;
  line-height: 1;
  display: none;
  width: 1250px;
}

.student-report-container-to-pdf .student-report-summary {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.student-report-container-to-pdf .student-report-summary .student-container {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}

.student-report-container-to-pdf .student-report-summary .student-container img {
  width: 40px;
  transform: translateY(10px);
}

.student-report-container-to-pdf .student-report-summary .student-container span {
  display: block;
  height: 100%;
  margin-left: 5px;
}

.student-report-container-to-pdf .student-report-summary .summary-item {
  font-weight: 500;
}

.student-report-container-to-pdf .student-report-summary .logo img {
  height: 40px;
  width: 100%;
  object-fit: cover;
}

.student-report-container-to-pdf .student-report-line {
  margin: 20px 0;
  width: 100%;
  height: 2px;
  background-color: rgb(44, 44, 44);
}

.student-report-container-to-pdf .student-report-general-info {
  padding: 20px 0;
  margin-bottom: 40px;
}

.student-report-container-to-pdf .student-report-general-info .student-report-items-container {
  padding: 0 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.student-report-container-to-pdf
  .student-report-general-info
  .student-report-items-container
  .student-report-item {
  margin-top: 20px;
  text-align: center;
  min-width: 175px;
}

.student-report-container-to-pdf
  .student-report-general-info
  .student-report-items-container
  .student-report-item
  .item-value {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
}

.student-report-container-to-pdf
  .student-report-general-info
  .student-report-items-container
  .student-report-item
  .item-label {
  font-size: 24px;
  font-weight: 300;
}

.student-report-container-to-pdf .charts-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.student-report-container-to-pdf .charts-container .chart-container {
  margin-bottom: 100px;
  width: 580px;
}

.student-report-container-to-pdf .section-header {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}
